import request from "./request";
import { IRequest } from "@/shared/types/request";
import { IArchive } from "@/shared/types/archives";

export const getArchives = ({ bundle, platform, search, version, favorites }) =>
  request.get<never, IRequest<IArchive[]>>(`/archives`, {
    params: { bundle, platform, version, search, show_favourites: favorites },
  });

export const toggleFavorite = (id) =>
  request.post<never, IRequest<any>>(`/toggle-favourite/${id}`);
