import { getApps } from "@/shared/api/apps";
import { Module } from "vuex";
import { RootState } from "../index";
import { ElMessage } from "element-plus";

export interface IState {
  list: [];
}

const authModule: Module<IState, RootState> = {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    SET_LIST(state, list) {
      state.list = list;
    },
  },
  actions: {
    async getApps({ commit }, payload) {
      try {
        const response = await getApps(payload);
        commit("SET_LIST", response);
      } catch (e: any) {
        ElMessage({
          message: e,
          type: "error",
        });
      }
    },
  },
};

export default authModule;
