<template>
  <div class="apps-page" v-loading="loading">
    <div class="apps-page__top">
      <div class="apps-page__title">Apps</div>
      <div class="apps-page__top-actions">
        <el-input
          :prefix-icon="Search"
          v-model="filter.search"
          placeholder="Search"
          @input="searchVersion"
        />
      </div>
    </div>
    <div class="apps-page__main">
      <div class="apps-page__content">
        <div class="apps-page__content-top">
          <div class="apps-page__platforms">
            <el-button
              class="apps-page__platform"
              v-for="platform in PLATFORMS"
              :key="platform.value"
              size="small"
              :type="platform.value === filter.platform ? 'primary' : ''"
              text
              bg
              @click="onFilter(platform.value, 'platform')"
              >{{ platform.name }}</el-button
            >
          </div>
          <el-button
            class="apps-page__favourite"
            size="small"
            :type="''"
            text
            bg
            @click="router.push({ name: 'favorites' })"
          >
            <template #icon>
              <el-icon size="12">
                <IcFavourite />
              </el-icon> </template
            >Favourites</el-button
          >
        </div>
        <div class="apps-page__items">
          <AppCard
            v-for="app in apps"
            :key="app.id"
            :field="app"
            @download="handleDownloadFile"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Search } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { PLATFORMS } from "../consts";
import getUserPlatform from "@/shared/lib/getUserPlatform";
import IcFavourite from "@/shared/ui/icons/IcFavourite.vue";
import { AppCard } from "@/shared/ui/components/AppCard";
import { IApp } from "@/shared/types/apps";
import { debounce } from "lodash";
import { downloadFile } from "@/shared/lib/files";

const store = useStore();
const route = useRoute();
const router = useRouter();

const filter = reactive({
  platform: null,
  search: null,
});
const loading = ref(false);

let searchVersion = () => {
  getApps();
};

onMounted(async () => {
  filter.platform = getUserPlatform();
  try {
    await getApps();
  } catch (e: any) {
    ElMessage({
      message: e,
      type: "error",
    });
  }

  searchVersion = debounce(searchVersion, 400);
});

const apps = computed<IApp[]>(() => {
  return store.state.apps.list;
});

const getApps = async () => {
  return await store.dispatch("apps/getApps", filter);
};
const handleDownloadFile = async (fileUrl: string, id: string) => {
  loading.value = true;
  await downloadFile(id, fileUrl.split("/").at(-1));
  loading.value = false;
};

const onFilter = (val, prop) => {
  if (filter[prop] === val) {
    filter[prop] = null;
  } else {
    filter[prop] = val;
  }
  getApps();
};
</script>

<style lang="scss">
.apps-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;
    height: 56px;
    flex-shrink: 0;
    margin-bottom: 16px;
  }
  &__title {
    font-size: 18px;
    font-weight: 800;
    line-height: 26px;
  }
  &__top-actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__main {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }
  &__content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    border-radius: var(--Radius-border-radius-base, 4px);
    background: var(--Color-Background-bg-color, #fff);
  }
  &__content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid var(--Color-Border-border-color-lighter, #ebeef5);
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    padding: 24px;
  }
  @media (max-width: 1440px) {
    &__items {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  @media (max-width: 1280px) {
    &__top {
      padding: 0 24px;
    }
    &__items {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (max-width: 744px) {
    &__top {
      padding: 0 20px;
    }
    &__favourite {
      span {
        display: none !important;
      }
    }
    &__items {
      grid-template-columns: repeat(2, 1fr);
    }
    &__items,
    &__content-top {
      padding: 16px;
    }
  }
  @media (max-width: 480px) {
    &__items {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
