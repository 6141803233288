<template>
  <div class="app-page" v-loading="loading">
    <div class="app-page__top">
      <div class="app-page__top-left">
        <el-button
          class="app-page__back"
          :icon="Back"
          link
          @click="router.push({ name: 'apps' })"
          >Back</el-button
        >
        <el-divider class="app-page__top-divider" direction="vertical" />

        <div class="app-page__title">{{ app?.title || "-" }}</div>
      </div>

      <div class="app-page__top-actions">
        <el-input
          :prefix-icon="Search"
          v-model="filter.search"
          placeholder="Search"
          @input="searchVersion"
        />
      </div>
    </div>
    <div class="app-page__main">
      <div class="app-page__content">
        <div class="app-page__content-top">
          <div class="app-form__platforms">
            <el-button
              class="app-form__platform"
              v-for="platform in PLATFORMS"
              :key="platform.value"
              size="small"
              :type="platform.value === filter.platform ? 'primary' : ''"
              text
              bg
              @click="onFilter(platform.value, 'platform')"
              >{{ platform.name }}</el-button
            >
          </div>
          <el-button
            class="app-page__favourite"
            size="small"
            :type="''"
            text
            bg
            @click="
              router.push({ name: 'favorite', params: { id: app.bundle } })
            "
          >
            <template #icon>
              <el-icon size="12">
                <IcFavourite />
              </el-icon> </template
            >Favourites</el-button
          >
        </div>
        <div class="app-page__items">
          <AppArchive
            v-for="archive in archives"
            :key="archive.id"
            :field="archive"
            @download="handleDownloadFile"
            @favorite="onToggleFavorite"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Search } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { PLATFORMS } from "../consts";
import getUserPlatform from "@/shared/lib/getUserPlatform";
import IcFavourite from "@/shared/ui/icons/IcFavourite.vue";
import { AppArchive } from "@/shared/ui/components/AppArchive";
import { IArchive } from "@/shared/types/archives";
import { Back } from "@element-plus/icons-vue";
import { debounce } from "lodash";
import { downloadFile } from "@/shared/lib/files";

const store = useStore();
const route = useRoute();
const router = useRouter();

const filter = reactive({
  platform: route.params.platform,
  bundle: route.params.id,
  search: null,
});

const loading = ref(false);
const app: Partial<AppArchive> = reactive({});

let searchVersion = () => {
  getArchives();
};

onMounted(async () => {
  filter.platform = getUserPlatform();
  try {
    await getArchives();
    app.title = archives.value[0].title;
    app.bundle = archives.value[0].bundle;
  } catch (e: any) {
    ElMessage({
      message: e,
      type: "error",
    });
  }

  searchVersion = debounce(searchVersion, 400);
});

const archives = computed<IArchive[]>(() => {
  return store.state.archives.list;
});

const getArchives = async () => {
  return await store.dispatch("archives/getArchives", filter);
};
const handleDownloadFile = async (fileUrl: string, id: string) => {
  loading.value = true;
  await downloadFile(id, fileUrl.split("/").at(-1));
  loading.value = false;
};

const onFilter = (val, prop) => {
  if (filter[prop] === val) {
    filter[prop] = null;
  } else {
    filter[prop] = val;
  }
  getArchives();
};

const onToggleFavorite = (id) => {
  return store.dispatch("archives/toggleFavorite", id);
};
</script>

<style lang="scss">
.app-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;
    height: 56px;
    flex-shrink: 0;
    margin-bottom: 16px;
  }
  &__top-left {
    display: flex;
    align-items: center;
  }
  &__top-divider {
    margin: 0 16px !important;
  }
  &__title {
    font-size: 18px;
    font-weight: 800;
    line-height: 26px;
  }
  &__top-actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__main {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  }
  &__content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    border-radius: var(--Radius-border-radius-base, 4px);
    background: var(--Color-Background-bg-color, #fff);
  }
  &__content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid var(--Color-Border-border-color-lighter, #ebeef5);
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    padding: 24px;
  }
  @media (max-width: 1280px) {
    &__top {
      padding: 0 24px;
    }
    &__back {
      span {
        display: none !important;
      }
    }
  }
  @media (max-width: 744px) {
    &__top {
      padding: 0 20px;
    }
    &__favourite {
      span {
        display: none !important;
      }
    }
    &__items,
    &__content-top {
      padding: 16px;
    }
  }
}
</style>
