import { getArchives, toggleFavorite } from "@/shared/api/archives";
import { Module } from "vuex";
import { RootState } from "../index";
import { ElMessage } from "element-plus";
import { IArchive } from "@/shared/types/archives";

export interface IState {
  list: IArchive[];
  favorites: [];
}

const authModule: Module<IState, RootState> = {
  namespaced: true,
  state: {
    list: [],
    favorites: [],
  },
  mutations: {
    SET_LIST(state, list) {
      state.list = list;
    },
    CHANGE_LIST(state, id) {
      const item = state.list.find((item) => item.id === id);
      item.favourite_id = !item.favourite_id;
    },
    SET_FAVORITES(state, list) {
      state.list = list;
    },
    REMOVE_FAVORITE(state, id) {
      state.list = state.list.filter((item) => item.id !== id);
    },
  },
  actions: {
    async getArchives({ commit }, payload) {
      try {
        const response = await getArchives(payload);
        commit("SET_LIST", response);
      } catch (e: any) {
        ElMessage({
          message: e,
          type: "error",
        });
      }
    },

    async getFavorites({ commit }, payload) {
      try {
        const response = await getArchives(payload);
        commit("SET_FAVORITES", response);
      } catch (e: any) {
        ElMessage({
          message: e,
          type: "error",
        });
      }
    },
    async removeFavorite({ commit }, payload) {
      try {
        await toggleFavorite(payload);
        commit("REMOVE_FAVORITE", payload);
      } catch (e) {
        ElMessage({
          message: e,
          type: "error",
        });
      }
    },
    async toggleFavorite({ commit }, payload) {
      try {
        await toggleFavorite(payload);
        commit("CHANGE_LIST", payload);
      } catch (e) {
        ElMessage({
          message: e,
          type: "error",
        });
      }
    },
  },
};

export default authModule;
