<template>
  <el-menu
    :default-active="$route.path"
    class="side-naw"
    :collapse="false"
    router
  >
    <div v-for="item in menuItems" :key="item.id">
      <el-menu-item
        :id="'menu-item-' + item.id"
        class="menu-title"
        :index="item.link"
        :route="item.link"
      >
        <template #title>
          <div>
            <el-icon>
              <component :is="item.icon"></component>
            </el-icon>
            <span>{{ item.label }}</span>
          </div>
        </template>
      </el-menu-item>
    </div>
  </el-menu>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { menuItems } from "../menu";
import IcApps from "@/shared/ui/icons/IcApps.vue";

export default defineComponent({
  components: {
    IcApps,
  },
  setup() {
    return {
      menuItems,
    };
  },
});
</script>

<style lang="scss">
.side-naw {
  border: none !important;
  background-color: rgba(242, 243, 245, 1) !important;
  .is-active path {
    fill: #409eff;
  }
}
</style>
