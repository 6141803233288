<template>
  <div class="layout-vertical">
    <div id="layout-wrapper">
      <SideBar class="layout-vertical__side-bar" />
      <div class="layout-vertical__main-content">
        <div class="layout-vertical__page-content">
          <div class="layout-vertical__container-fluid">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SideBar } from "@/widgets/SideBar/index";

/**
 * Vertical layout
 */
export default {
  name: "AppVertical",
  components: { SideBar },
};
</script>

<style lang="scss" scoped>
.layout-vertical {
  &__main-content {
    min-height: 100vh;
    margin-left: 195px;
    overflow: hidden;
    position: relative;
  }
  &__container-fluid {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  @media (max-width: 1280px) {
    &__main-content {
      margin-left: 0;
    }
    &__side-bar {
      display: none;
    }
  }
}
</style>
