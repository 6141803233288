import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createBlock(_component_el_menu, {
    "default-active": _ctx.$route.path,
    class: "side-naw",
    collapse: false,
    router: ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id
        }, [
          _createVNode(_component_el_menu_item, {
            id: 'menu-item-' + item.id,
            class: "menu-title",
            index: item.link,
            route: item.link
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_el_icon, null, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon)))
                  ]),
                  _: 2
                }, 1024),
                _createElementVNode("span", null, _toDisplayString(item.label), 1)
              ])
            ]),
            _: 2
          }, 1032, ["id", "index", "route"])
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["default-active"]))
}