<template>
  <div class="app-card">
    <IcStatus
      class="app-card__different"
      v-if="field.is_different_from_previous"
    />
    <div
      class="app-card__img-wrapper"
      @click="$router.push({ name: 'app', params: { id: field.bundle } })"
    >
      <img
        class="app-card__img"
        :src="field.attachment_url"
        :alt="field.title"
      />
    </div>

    <div
      class="app-card__title"
      @click="$router.push({ name: 'app', params: { id: field.bundle } })"
    >
      {{ field.title }}
    </div>
    <div class="app-card__epic">{{ field.version }}</div>
    <div class="app-card__date">
      {{ formatDateTime(field.updated_at) }}
    </div>
    <div class="app-card__bundle">
      {{ field.bundle }}
    </div>
    <div class="app-card__bottom">
      <el-button
        class="app-card__favourite"
        type="success"
        @click="$emit('download', field.attachment_url, field.id)"
      >
        <template #icon>
          <el-icon size="14">
            <IcDownload />
          </el-icon> </template
      ></el-button>
      <div class="app-card__comment">
        Comment Lorem ipsum dolor sit amet, consectetur adipiscing elit Morbi
        non accumsan lacus, et cursus turpis. Integer eu est diam. Suspendisse
        aliquet est ut arcu suscipit porta.
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import IcDownload from "@/shared/ui/icons/IcDownload.vue";
import IcStatus from "@/shared/ui/icons/IcStatus.vue";

defineProps({
  field: {
    type: Object,
    default: () => ({}),
  },
});

const formatDateTime = (dateTimeString: string) => {
  const date: Date = new Date(dateTimeString);
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const formattedDateTime = date.toLocaleDateString("ru-RU", options);
  return formattedDateTime;
};
</script>

<style lang="scss" scoped>
.app-card {
  position: relative;
  padding: 16px;
  border-radius: var(--Radius-border-radius-base, 4px);
  background: var(--Color-Fill-fill-color-light, #f5f7fa);
  &__img-wrapper {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 24px;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 8px;
  }
  &__img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    object-fit: contain;
  }
  &__different {
    position: absolute;
  }
  &__favorite {
    position: absolute;
    right: 16px;
    top: 16px;
  }
  &__title {
    overflow: hidden;
    color: var(--Color-Text-text-color-primary, #303133);
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 800;
    line-height: 24px; /* 150% */
    cursor: pointer;
  }
  &__epic {
    overflow: hidden;
    color: var(--Color-Text-text-color-primary, #303133);
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 800;
    line-height: 20px; /* 166.667% */
    margin-bottom: 8px;
  }
  &__date {
    overflow: hidden;
    color: var(--Color-Text-text-color-regular, #606266);
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
  }
  &__bundle {
    overflow: hidden;
    color: var(--Color-Text-text-color-secondary, #909399);
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    margin-top: -4px;
    margin-bottom: 8px;
  }
  &__bottom {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__comment {
    color: var(--Color-Text-text-color-regular, #606266);
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
