<template>
  <div class="side-bar">
    <img
      class="side-bar__logo"
      src="@/shared/assets/icons/logo.svg"
      alt="Bimi Boo"
    />
    <SideNav class="side-bar__nav" />
  </div>
</template>

<script>
import { SideNav } from "../../SideNav/index";

export default {
  components: { SideNav },
};
</script>

<style lang="scss" scoped>
.side-bar {
  width: 195px;
  z-index: 1001;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 0;
  padding: 12px 0;
  &__logo {
    margin: 0 auto 34px;
    display: block;
  }
}
</style>
