import request from "@/shared/api/request";

const keycloakAuth = ({ app, store }: any, callback: () => any) => {
  app.config.globalProperties.$keycloak
    .init({ onLoad: "login-required" })
    .then(async (auth: boolean) => {
      if (!auth) {
        window.location.reload();
      } else {
        await app.config.globalProperties.$keycloak
          .loadUserInfo()
          .then(async (res: boolean) => {
            if (res) {
              request.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${app.config.globalProperties.$keycloak.token}`;

              setInterval(() => {
                app.config.globalProperties.$keycloak
                  .updateToken(70)
                  .success((refreshed: boolean) => {
                    if (refreshed) {
                      request.defaults.headers.common[
                        "Authorization"
                      ] = `Bearer ${app.config.globalProperties.$keycloak.token}`;
                    }
                  })
                  .error(() => {
                    console.log("Failed to refresh token");
                  });
              }, 60000);
              return callback();
            }
          });
      }
    })
    .catch(() => {
      console.error("Authenticated Failed");
    });
};

export default keycloakAuth;
