import { createApp } from "vue";
import App from "./App.vue";
import router from "./providers/router";
import store from "./providers/store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import Auth from "@/shared/plugins/authentication";
import keycloakAuth from "@/shared/lib/keycloakAuth";
import "./style/index.scss";

export const app = createApp(App);

app.use(ElementPlus);

app.use(Auth);

app.use(store);
app.use(router);

keycloakAuth({ app, store }, () => {
  app.mount("#app");
});
