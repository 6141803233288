import { ElMessage } from "element-plus";
import request from "@/shared/api/request";

export const fetchFile = (id) =>
  request.get<never, Blob>(`/download/${id}`, { responseType: "blob" });

export const downloadBlobAsFile = (blob: Blob, fileName: string): void => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadFile = async (path, name) => {
  try {
    const data = await fetchFile(path);
    const blob = new Blob([data], { type: data.type });
    downloadBlobAsFile(blob, name);
  } catch (e) {
    console.error(e);
    ElMessage.error(e?.message ?? "Something went wrong");
  }
};
