<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M3.75 7.125C3.75 6.50368 4.25368 6 4.875 6H6.375C6.99632 6 7.5 6.50368 7.5 7.125V8.625C7.5 9.24632 6.99632 9.75 6.375 9.75H4.875C4.25368 9.75 3.75 9.24632 3.75 8.625V7.125Z"
      fill="#409EFF"
    />
    <path
      d="M9 6.375C9 6.16789 9.16789 6 9.375 6H14.625C14.8321 6 15 6.16789 15 6.375C15 6.58211 14.8321 6.75 14.625 6.75H9.375C9.16789 6.75 9 6.58211 9 6.375Z"
      fill="#409EFF"
    />
    <path
      d="M9 7.875C9 7.66789 9.16789 7.5 9.375 7.5H13.875C14.0821 7.5 14.25 7.66789 14.25 7.875C14.25 8.08211 14.0821 8.25 13.875 8.25H9.375C9.16789 8.25 9 8.08211 9 7.875Z"
      fill="#409EFF"
    />
    <path
      d="M9.375 9C9.16789 9 9 9.16789 9 9.375C9 9.58211 9.16789 9.75 9.375 9.75H12.375C12.5821 9.75 12.75 9.58211 12.75 9.375C12.75 9.16789 12.5821 9 12.375 9H9.375Z"
      fill="#409EFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 2.25C1.34315 2.25 0 3.59315 0 5.25V12.75C0 14.4069 1.34315 15.75 3 15.75H15C16.6569 15.75 18 14.4069 18 12.75V5.25C18 3.59315 16.6569 2.25 15 2.25H3ZM15 3.75H3C2.17157 3.75 1.5 4.42157 1.5 5.25V12.75C1.5 13.5784 2.17157 14.25 3 14.25H15C15.8284 14.25 16.5 13.5784 16.5 12.75V5.25C16.5 4.42157 15.8284 3.75 15 3.75Z"
      fill="#409EFF"
    />
  </svg>
</template>
