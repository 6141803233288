import { RouteRecordRaw } from "vue-router";
import { AppsPage } from "@/pages/apps/index";
import { AppPage } from "@/pages/app/index";
import { FavoritePage } from "@/pages/favorite/index";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/apps",
  },
  {
    path: "/apps",
    name: "apps",
    component: AppsPage,
  },
  {
    path: "/app/:id",
    name: "app",
    props(route) {
      return {
        id: route.params.id,
      };
    },
    component: AppPage,
  },
  {
    path: "/favorite",
    name: "favorites",
    component: FavoritePage,
  },
  {
    path: "/favorite/:id",
    name: "favorite",
    props(route) {
      return {
        id: route.params.id,
      };
    },
    component: FavoritePage,
  },
];
