<template>
  <MainLayout>
    <router-view />
  </MainLayout>
</template>

<script>
import { MainLayout } from "./layouts/MainLayout/index";

export default {
  name: "app",
  components: {
    MainLayout,
  },
};
</script>
