import { createApp } from "vue";
import Keycloak from "keycloak-js";

const initOptions = {
  url: process.env.VUE_APP_OIDC_URL,
  realm: process.env.VUE_APP_OIDC_REALM,
  clientId: process.env.VUE_APP_OIDC_CLIENT_ID,
  onLoad: "login-required",
};

const _keycloak = new Keycloak(initOptions);

const Plugin = {
  install: (app: any) => {
    app.config.globalProperties.$keycloak = _keycloak;
  },
};

const app = createApp({});
app.use(Plugin);

export default Plugin;
