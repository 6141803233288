export default function () {
  const userAgent = navigator.userAgent;

  if (/android/i.test(userAgent)) {
    return "android";
  }

  if (
    /Kindle/i.test(userAgent) ||
    /Silk/i.test(userAgent) ||
    /KFTT/i.test(userAgent) ||
    /KFOT/i.test(userAgent) ||
    /KFJWA/i.test(userAgent) ||
    /KFJWI/i.test(userAgent) ||
    /KFSOWI/i.test(userAgent) ||
    /KFTHWA/i.test(userAgent) ||
    /KFTHWI/i.test(userAgent) ||
    /KFAPWA/i.test(userAgent) ||
    /KFAPWI/i.test(userAgent)
  ) {
    return "amazon";
  }

  // if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //   return "ios";
  // }

  return "android";
}
