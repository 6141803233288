export const PLATFORMS = [
  {
    name: "Google Play",
    value: "android",
  },
  {
    name: "App Store",
    value: "ios",
  },
  {
    name: "Amazon",
    value: "amazon",
  },
];
