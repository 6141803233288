<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 4.62L8.27 6.985L9.09 10.5L6 8.635L2.91 10.5L3.725 6.985L1 4.62L4.595 4.31L6 1L7.405 4.31L11 4.62Z"
      fill="#606266"
    />
  </svg>
</template>
