<template>
  <svg
    v-if="active"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M7.99992 11.5133L12.1199 14L11.0266 9.31334L14.6666 6.16001L9.87325 5.74668L7.99992 1.33334L6.12659 5.74668L1.33325 6.16001L4.96659 9.31334L3.87992 14L7.99992 11.5133Z"
      fill="#E6A23C"
    />
  </svg>
  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M7.99992 10.26L5.49325 11.7733L6.15325 8.92001L3.93992 7.00001L6.85992 6.75334L7.99992 4.06001L9.13992 6.75334L12.0599 7.00001L9.84659 8.92001L10.5066 11.7733M14.6666 6.16001L9.87325 5.75334L7.99992 1.33334L6.12659 5.75334L1.33325 6.16001L4.96659 9.31334L3.87992 14L7.99992 11.5133L12.1199 14L11.0266 9.31334L14.6666 6.16001Z"
      fill="#A8ABB2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  cursor: pointer;
}
</style>
