import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
// import store from "@/app/providers/store";
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach(async (to, from, next) => {
//   const check = to.matched?.some((route) => hasRoleAccess(route.meta.acl));
//   if (check) {
//     next();
//   } else if (to.path !== "/404") {
//     next({ name: "404", query: { redirect: to.from } });
//   }
//   next();
// });

// function hasRoleAccess(roles = []) {
//   return roles.some((role) => store.state.auth.roles.includes(role));
// }

export default router;
