import axios, { type AxiosResponse } from "axios";
import type { AxiosInstance } from "axios";

export const API_PATH = process.env.VUE_APP_API_PATH
  ? process.env.VUE_APP_API_PATH
  : "https://mybimiboo.com/api";

// axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true;

const service: AxiosInstance = axios.create({
  baseURL: API_PATH,
  timeout: 60 * 4 * 1000,
});

service.interceptors.response.use(
  (res: AxiosResponse) => {
    const { data } = res;
    if (data.code) {
      if (data.code === 401 || data.code === 403) {
        throw new Error(data.message);
      }

      return data;
    } else {
      return data;
    }
  },
  (error) => {
    throw new Error(error);
  }
);

export default service;
