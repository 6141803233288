import { createStore } from "vuex";
import apps from "./modules/apps";
import archives from "./modules/archives";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RootState {}

export default createStore<RootState>({
  modules: { apps, archives },
});
