<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.08753 6.97833L5.35028 9.24108V9.23876C6.26143 10.1497 7.73843 10.1497 8.6496 9.23876L10.9124 6.97601C10.9172 6.9713 10.922 6.96652 10.9267 6.96165C11.1505 6.72991 11.1441 6.36063 10.9124 6.13683C10.6806 5.91302 10.3113 5.91944 10.0875 6.15118L7.82478 8.41393C7.74472 8.4914 7.65396 8.55702 7.55528 8.60876C7.55581 8.6015 7.55783 8.59474 7.55985 8.58796C7.56222 8.58002 7.5646 8.57206 7.5646 8.56326L7.58328 0.584423C7.58328 0.262259 7.32212 0.00109865 6.99995 0.00109863C6.67779 0.00109862 6.41663 0.262259 6.41663 0.584423L6.39795 8.56676C6.39795 8.57113 6.39912 8.57507 6.40029 8.57901C6.40146 8.58294 6.40263 8.58688 6.40263 8.59126C6.32008 8.54223 6.24368 8.48349 6.17513 8.41626L3.91238 6.15351C3.68633 5.93517 3.32793 5.93517 3.10188 6.15351C2.87015 6.37731 2.86372 6.74659 3.08753 6.97833ZM12.8333 11.0822V9.91557C12.8333 9.59338 13.0945 9.33222 13.4167 9.33222C13.7388 9.33222 14 9.59338 14 9.91554V11.0822C13.9981 12.6922 12.6934 13.997 11.0834 13.9989L2.91668 13.9989C1.30665 13.997 0.00194135 12.6922 0 11.0822V9.91557C0 9.59338 0.261187 9.33224 0.583351 9.33224C0.905515 9.33224 1.16668 9.5934 1.16668 9.91557L1.16668 11.0822C1.16668 12.0487 1.95018 12.8322 2.91668 12.8322H11.0833C12.0498 12.8322 12.8333 12.0487 12.8333 11.0822Z"
      fill="white"
    />
  </svg>
</template>
