<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <circle
      cx="8"
      cy="8"
      r="5"
      fill="#F56C6C"
      stroke="#F5F7FA"
      stroke-width="2"
    />
  </svg>
</template>
