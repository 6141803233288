<template>
  <div class="layout">
    <el-progress
      v-if="isProgress"
      :percentage="percentage"
      :show-text="false"
      class="layout__progress"
    />
    <VerticalLayout class="layout_vertical">
      <slot />
    </VerticalLayout>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { Ref } from "vue";
import { useRoute } from "vue-router";
import { VerticalLayout } from "../../VerticalLayout/index";

const route = useRoute();

const percentage: Ref<number> = ref(0);
const isProgress: Ref<boolean> = ref(false);

watch(route, () => {
  progress();
});

onMounted(() => {
  progress();
});

const progress = () => {
  percentage.value = 0;
  isProgress.value = true;
  const interval = setInterval(() => {
    percentage.value = (percentage.value % 100) + 10;
    if (percentage.value === 100) {
      clearInterval(interval);
      setTimeout(() => {
        isProgress.value = false;
      }, 500);
    }
  });
};
</script>

<style lang="scss">
.layout {
  position: relative;
  &__progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  &_vertical {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
</style>
